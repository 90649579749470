@import url('https://fonts.googleapis.com/css2?family=Crimson+Text&display=swap');

$book: #624A2E;
$height: 40em;
$height_sm: 36em;

* {
  box-sizing: border-box;
}

#wrapper {
  margin: 0 auto;
  max-width: 1800px;
  padding: 0;
  top: 0;
  left: 0;
}

header {
  display: flex;
  // height: 10vh;
  justify-content: space-between;
  align-items: center;
  // border: 1px solid red;
  margin: 0px;
  padding: 0 4em;

  #logo img {
    height: 8em;
    cursor: pointer;
  }

  .date-picker {
    background-color: white;
    border-radius: 4px;
    margin-right: 5em;
  }
}

main {
  display: flex;
  justify-content: center;
  align-items: center;
  // height: 80vh;
  margin: 0 auto;
  gap: 1em;

  h1, h2 {
    font-family: 'Crimson Text', serif;
    font-size: 2.5em;
    padding-top: 1em;
    margin: 10px 25px 0 15px;
  }

  h1 {
    color: white;
    text-shadow: 1px 1px 2px #000000;
    padding: 1.3em 0.4em;
  }

  .controls {
    width: 12em;
    // border: 1px solid blue;
    height: $height;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    margin: 0 auto;
  }

  #previous-button, #previous-image, #next-image, #next-button {
    text-align: center;
    cursor: pointer;
  }

  #previous-image img, #next-image img, #previous-image iframe, #next-image iframe {
    border-radius: 8px;
    height: 5em;
    width: 6em;
    text-align: center;
    box-shadow: 1px 0px 20px white;
  }

  #book-container {
    display: flex;
    // height: 80%;
  }

  .book {
    width: 30em;
    height: $height;
    // border: 1px solid pink;
    background-color: $book;
    align-items: center;
  }

  #left-book-container, #right-book-container {
    text-align: center;
    // box-shadow: 1px 0px 20px grey;
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  }
  
  #right-book-container {
    border-radius: 4px 20px 20px 4px;
    // box-shadow: 1px 0px 20px grey;
  }

  #left-book-container {
    border-radius: 20px 4px 4px 20px;
  }

  #image-container, #explanation-container {
    background-color: white;
    height: 95%;
  }

  #image-container {
    height: 96%;
    border-radius: 20px 2px 2px 20px;
    margin: 0px 0 0 10px;
    background-repeat: none;
    background-size: cover;
    background-position: center;
    background-color: #000000;
  }

  #explanation-container {
    height: 96%;
    border-radius: 2px 20px 20px 2px;
    margin: 10px 10px 0px 0;
    overflow: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  #explanation {
    padding: 2em;
    letter-spacing: 0.1em;
    line-height: 1.4em;
    hyphens: auto;
    text-align: justify;
  }
}

#previous, #next, #random {
  border: none;
  border-radius: 4px;
  color: #170F1E;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  text-decoration: none;
  transition: 0.2s;
  padding: .75rem 1rem;
  line-height: 1.25rem;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
}

#previous, #next {
  background-color: #FFFFFF;
  box-sizing: border-box;
  margin-top: 1em;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  touch-action: manipulation;
  width: 6em;
  &:hover {
    transform: rotate(-3deg);
  }
  &:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
  &:focus-visible {
    box-shadow: none;
  }
  &:active {
    transform: scale(0.95) rotate(-3deg);
  }
}

#random {
  animation: 1.5s ease infinite alternate running shimmer;
  background: linear-gradient(90deg, #FFE27D 0%, #64E3FF 30%, #9192FF 85%);
  background-size: 200% 100%;
  box-shadow: -2px -2px 10px rgba(255, 227, 126, 0.5), 2px 2px 10px rgba(144, 148, 255, 0.5);
  overflow: hidden;
  position: relative;
  
  &:hover {
    transform: rotate(-3deg);
  }
  
  &:active {
    transform: scale(0.95) rotate(-3deg);
  }

}

@keyframes shimmer {
  to {
    background-size: 100% 100%;
    box-shadow: -2px -2px 6px rgba(255, 227, 126, 0.5), 2px 2px 6px rgba(144, 148, 255, 0.5);
  }
}

footer {
  // border: 1px solid green;
  display: flex;
  align-items: center;
  justify-content: center;
}

footer > button {
  margin: 2em 0 0 0;
}

iframe {
  // height: 325px;
  // width: 400px;
  height: 60%;
  width: 100%;
}

#sound {
  visibility: hidden;
}

.shake {
  animation: shake 0.5s;
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

// TABLETS & LARGER MOBILES
@media (max-width: 1000px) {
  
  #sound {
    display: none;
  }
  
  header {
    padding: 0;
    // justify-content: center;

    #logo img {
      height: 4em;
    }

    .date-picker {
      // margin: 0 auto;
      margin-right: 5%;
    }
  }

  main {

    width: 95%;
    // display: block;
    flex-direction: row;
    flex-wrap: wrap;
    perspective: 1000px;

    #book-container {
      flex-direction: column;
      order: -1;
      height: 60vh;
      width: 95vw;
      position: relative;
      transition: transform 0.6s;
      transform-style: preserve-3d;

      // BACKUP - works! BUT OLD - on hold.
      // &:active {
      //   transform: rotateY(180deg);
      // }

      /* This will rotate the container by 180 degrees on click */
      &.rotate {
        transform: rotateY(180deg);
      }
      
      #right-book-container, #left-book-container {
        height: 100%;
        width: 100%;
        // flex-grow: 1;
        margin: 0 auto;
        position: absolute;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
      }

      // BACKUP - works!
      #right-book-container {
        transform: rotateY(180deg);
      }

    }

    #previous-container, #next-container {
      height: auto;
      flex-direction: column;
      display: block;
      text-align: center;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
    }

    .book {
      background-color: transparent;
      user-select: none;
    }

    #image-container, #explanation-container {
      border: 1px solid white;
      box-shadow: white 0px 2px 8px 2px;
      border-radius: 5px;
      padding: 0;
      margin: 0;
    }

    .controls {
      display: block;
      flex-direction: row;
      width: auto;
    }

    footer > button {
      margin: 2em 0 2em 0;
    }

  }

}

// MOBILE DEVICES (INHERITING ABOVE)
@media (max-width: 400px) {

  main h1, main h2 {
    font-size: 2em;
  }

  #previous-image, #next-image {
    display: none;
  }

  #previous, #next {
    margin-top: 0;
  }

  iframe {
    height: 40%;
  }


  #previous, #next, #random {
    &:hover {
      // transform: rotate(-3deg);
      transform: none;
    }
    &:active {
      transform: scale(0.95) rotate(-3deg);
    }
  }
}


// SMALLER LAPTOPS
@media (max-width: 1200px) {
  
  header {
    #logo img {
      height: 6em;
    }
  }

  main {
    flex-wrap: wrap;
    // flex-basis: 100%;
  }

  #book-container {
    order: -1;
    // flex-grow: 1;
    // flex: 1;
  }

  #previous-container, #next-container {
    height: auto;
    margin-top: 1em;
    flex-basis: 20%;  // hacky way to make sure previous/next buttons wrap properly
  }

  main .controls, main .book {
    height: $height_sm;
  }
}

// MEDIUM LAPTOPS
@media (max-width: 1600px) {
  
  header {
    #logo img {
      height: 6em;
    }
  }

  main .controls, main .book {
    height: $height_sm;
  }
}